<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap v-if="feedback">
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs10 align-self-center text-left py-8>
            <span style="font-family: poppinsbold; font-size: 20px"
              >Feedback Details</span
            >
          </v-flex>
          <v-flex xs2 align-self-center text-left py-8>
            <v-btn
              dark
              outlined
              tile
              class="text-none"
              small
              depressed
              color="blue"
              @click="scrollTo()"
            >
              <v-icon>mdi-reply</v-icon>&nbsp; <span>Reply</span>
            </v-btn>
          </v-flex>
          <v-flex xs12 lg8>
            <v-layout wrap>
              <v-flex xs12 align-self-center text-left v-if="feedback.guest">
                <span class="textField1"><b> Name of the Guest</b></span>
              </v-flex>
              <v-flex
                xs12
                align-self-center
                py-2
                text-left
                v-if="feedback.guest"
              >
                <v-text-field
                  color="#68D389"
                  class="textField2"
                  dense
                  v-model="feedback.guest.name"
                  outlined
                  readonly
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-self-center text-left v-if="feedback.guest">
                <span class="textField1"><b> Email </b></span>
              </v-flex>
              <v-flex
                xs12
                align-self-center
                py-2
                text-left
                v-if="feedback.guest"
              >
                <v-text-field
                  color="#68D389"
                  class="textField2"
                  dense
                  v-model="feedback.guest.email"
                  outlined
                  required
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-self-center text-left v-if="feedback">
                <span class="textField1"
                  ><b>
                    How would you rate your experience at Mudumalai and the
                    service of the staff? What did you like about Mudumalai?
                    Suggest improvements to make your next visit better.</b
                  ></span
                >
              </v-flex>
              <v-flex xs12 align-self-center py-2 text-left v-if="feedback">
                <v-textarea
                  color="#68D389"
                  class="textField2"
                  dense
                  v-model="feedback.comment"
                  outlined
                  readonly
                  required
                ></v-textarea>
              </v-flex>
              <v-flex
                xs12
                align-self-center
                text-left
                v-if="feedback.guideName"
              >
                <span class="textField1">
                  <b> Name of your guide during visit </b></span
                >
              </v-flex>
              <v-flex
                xs12
                align-self-center
                py-2
                text-left
                v-if="feedback.guideName"
              >
                <v-text-field
                  color="#68D389"
                  class="textField2"
                  dense
                  v-model="feedback.guideName"
                  outlined
                  required
                  readonly
                ></v-text-field>
              </v-flex>

              <v-flex xs12 align-self-center text-left v-if="feedback.rating">
                <span class="textField1"><b> Rate his/her service?</b></span>
              </v-flex>
              <v-flex
                xs12
                align-self-center
                py-2
                text-left
                v-if="feedback.rating"
              >
                <v-text-field
                  color="#68D389"
                  class="textField2"
                  dense
                  v-model="feedback.rating"
                  outlined
                  required
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex
                xs12
                align-self-center
                text-left
                v-if="feedback.addeddate"
              >
                <span class="textField1"><b> Feedback Date </b></span>
              </v-flex>
              <v-flex
                xs12
                align-self-center
                py-2
                text-left
                v-if="feedback.addeddate"
              >
                <v-text-field
                  color="#68D389"
                  class="textField2"
                  dense
                  v-model="feedback.addeddate"
                  outlined
                  readonly
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 py-2>
                <v-divider></v-divider>
              </v-flex>
              <v-flex
                xs12
                align-self-center
                py-2
                text-left
                v-if="feedback.showFeedback"
              >
                <v-layout wrap>
                  <v-flex xs12 py-2>
                    <span class="textField1"
                      ><b> Programs to show this Feedback</b></span
                    >
                  </v-flex>
                  <v-flex
                    align-self-center
                    v-for="(item, i) in feedback.programmesToShow"
                    :key="i"
                  >
                    <v-chip small>{{ item.progName }}</v-chip>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 py-3 align-self-center>
                <v-checkbox
                  v-model="checkbox"
                  label=" Click to select or deselect programs to show this Feedback on Website"
                  @click="showDialog()"
                  :ripple="true"
                  hide-details
                  dense
                >
                </v-checkbox>
                <v-dialog persistent v-model="checkboxx" max-width="900">
                  <!-- <template v-slot:activator="{ on, attrs }">
                    <v-checkbox
                      v-model="checkbox"
                      label=" Do you want to  make this feedback visible on the site"
                      v-bind="attrs"
                      v-on="on"
                      :ripple="true"
                      hide-details
                      dense>
                    </v-checkbox>
                  </template> -->

                  <v-card>
                    <v-card-title class="text-h5 lighten-2">
                      Select program
                    </v-card-title>

                    <v-card-text>
                      <v-flex xs12 md12 align-self-center>
                        <v-combobox
                          v-model="program"
                          clearable
                          :items="programs"
                          item-text="progName"
                          item-value="_id"
                          dense
                          return-object
                          multiple
                          outlined
                          small-chips
                        ></v-combobox>
                      </v-flex>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        text
                        @click="(checkbox = false), (checkboxx = false)"
                      >
                        close
                      </v-btn>
                      <v-btn color="primary" text @click="visibleFeedback">
                        save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-flex>
              <v-flex xs12 align-self-center py-2 v-if="feedback.details">
                <v-layout wrap>
                  <v-flex xs12 align-self-center py-2 text-left>
                    <span class="textField1"><b> Attended Programs</b></span>
                    <span class="textField1"><b>/Program Details</b></span>
                  </v-flex>
                  <v-flex
                    xs12
                    align-self-center
                    py-2
                    text-left
                    v-if="feedback.ticket"
                  >
                    <span style="color: #68d389" class="textField1"
                      ><b> Ticket No: </b></span
                    >
                    <span class="textField1"
                      ><b> #{{ feedback.ticket.ticketNo }} </b></span
                    >
                  </v-flex>
                  <!-- <v-flex xs12  align-self-center py-2 text-left>
                  </v-flex> -->
                  <v-flex
                    xs6
                    lg3
                    pa-2
                    v-for="(item, i) in feedback.details"
                    :key="i"
                    align-self-center
                    py-2
                    text-left
                  >
                    <v-chip>{{ item.programme.progName }}</v-chip>
                  </v-flex>

                  <v-flex xs12 id="toMe">
                    <v-layout wrap>
                      <v-flex xs12 py-6>
                        <v-divider></v-divider>
                      </v-flex>
                      <v-flex xs12 align-self-center text-left v-if="feedback.reply">
                        <span class="textField1"><b> Reply Message</b></span>
                        &nbsp;<v-chip dark color="#68D389" x-small>Sent</v-chip>
                      </v-flex>
                      <v-flex xs12 align-self-center text-left v-else>
                        <span class="textField1"><b> Type reply</b></span>
                      </v-flex>
                      <v-flex xs12 py-2 align-self-center text-left>
                        <v-textarea
                          color="#68D389"
                          class="textField2"
                          dense
                          v-model="reply"
                          outlined
                          required
                          :readonly="feedback.reply?true:false"
                          :placeholder="feedback.reply?'':'Type here...'"
                        ></v-textarea>
                      </v-flex>
                      <v-flex xs12 align-self-center text-right v-if="!feedback.reply">
                        <v-btn
                          small
                          rounded
                          depressed
                          dark
                          color="#68D389"
                          @click="sendReply()"
                        >
                          <span>Send</span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 lg4>
            <v-layout wrap>
              <v-flex xs12 align-self-center v-if="feedback.image">
                <v-layout wrap justify-center v-if="feedback.image.length > 0">
                  <viewer :images="feedback.image" :key="feedback.image.length">
                    <span
                      v-for="item in feedback.image"
                      :key="item"
                      class="pa-2"
                    >
                      <img
                        :src="mediaURL + item"
                        height="150px"
                        width="150px"
                      />
                    </span>
                  </viewer>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      checkbox: false,
      checkboxx: false,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      feedback: [],
      programs: [],
      program: null,
      id: null,
      reply: null,
    };
  },
  beforeMount() {
    this.getData();
    this.getPrograms("started");
  },
  methods: {
    scrollTo() {
      document.getElementById("toMe").scrollIntoView({
        behavior: "smooth",
      });
    },
    showDialog() {
      if (this.checkbox) this.checkboxx = true;
      else this.checkboxx = false;
    },
    sendReply() {
      if (this.reply) {
        this.appLoading = true;
        axios({
          method: "post",
          url: "feedback/reply",
          data: {
            id: this.id,
            message: this.reply,
          },
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status) {
              this.getData();
            } else {
              this.showSnackBar = true;
              this.msg = response.data.msg;
              this.checkbox = false;
              this.getData();
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });


      } else {
        this.msg = "Please enter reply message!";
        this.showSnackBar = true;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "feedback/getlist",
        params: {
          ticketid: this.$route.query.feed,
          guestid: this.$route.query.guest,
          // status: "completed"
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.data) {
            this.feedback = response.data.data[0];
            if(this.feedback.reply) {
              this.reply=this.feedback.reply
            }
            this.id = response.data.data[0]._id;
            // if(response.data.data.showFeedback)
            // this.checkbox=true
            if (this.feedback.addeddate) {
              this.feedback.addeddate = this.formatDate(
                this.feedback.addeddate
              );
              if (this.feedback.showFeedback)
                this.program = this.feedback.programmesToShow;
            }
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },

    /// new line of codes
    getPrograms(val) {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/programme/getlist",
        params: { programmestatus: val },
      })
        .then((response) => {
          this.appLoading = false;
          this.programs = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    visibleFeedback() {
      this.appLoading = true;
      // if (this.program != null) {
      if (this.program.length > 0) {
        axios({
          method: "post",
          url: "feedback/managereview",
          data: {
            id: this.id,
            showFeedback: this.checkbox,
            programmesToShow: this.program,
          },
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status) {
              (this.program = null), (this.showSnackBar = true);
              this.msg = response.data.msg;
              this.checkboxx = false;
              this.checkbox = false;
              this.getData();
            } else {
              this.showSnackBar = true;
              this.msg = response.data.msg;
              this.checkbox = false;
              this.getData();
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      } else {
        axios({
          method: "post",
          url: "feedback/managereview",
          data: {
            id: this.id,
            showFeedback: false,
            // programmesToShow: this.program,
          },
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status) {
              (this.program = null), (this.showSnackBar = true);
              this.msg = response.data.msg;
              this.checkboxx = false;
              this.checkbox = false;
              this.getData();
            } else {
              this.showSnackBar = true;
              this.msg = response.data.msg;
              this.checkbox = false;
              this.getData();
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }

      // }
      //  else {
      //   this.showSnackBar = true;
      //   this.msg = "Please select a program";
      // }
    },
  },
};
</script>
